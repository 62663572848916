<template>
	<div class="day-picker__wrapper">
		<h4 class="picker-title">Выбрать день недели</h4>
		<div class="day-picker" ref="dayPicker">
			<h5 class="picked-date" @click="checkSM && $refs.dayPicker.classList.toggle('active')">
				<span>{{ dayList.find(el => el.value === selectedDay).title }}</span>

				<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M5.99997 7.42016C5.7849 7.42016 5.56987 7.33804 5.4059 7.17415L0.24617 2.01437C-0.0820566 1.68614 -0.0820566 1.15398 0.24617 0.825889C0.574263 0.497795 1.10632 0.497795 1.43457 0.825889L5.99997 5.39155L10.5654 0.826048C10.8936 0.497955 11.4256 0.497955 11.7537 0.826048C12.0821 1.15414 12.0821 1.6863 11.7537 2.01453L6.59404 7.17431C6.42999 7.33822 6.21495 7.42016 5.99997 7.42016Z"
						fill="white"
					/>
				</svg>
			</h5>
			<div class="radio-wrapper">
				<label
					v-for="day in dayList"
					:key="day.value"
					@click="checkSM && $refs.dayPicker.classList.remove('active')"
				>
					<input type="radio" :value="day.value" v-model="computedSelectedDay" />
					<span>{{ day.title }}</span>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		selectedDay: {
			type: String,
			required: true,
		},
	},
	data: () => ({
		dayList: [
			{ title: 'Понедельник', value: '1' },
			{ title: 'Вторник', value: '2' },
			{ title: 'Среда', value: '3' },
			{ title: 'Четверг', value: '4' },
			{ title: 'Пятница', value: '5' },
			{ title: 'Суббота', value: '6' },
			{ title: 'Воскресенье', value: '0' },
		],
	}),
	computed: {
		checkSM() {
			return window.innerWidth < 991;
		},
		computedSelectedDay: {
			get() {
				return this.selectedDay;
			},
			set(value) {
				this.$emit('changeSelectedDay', value);
			},
		},
	},
};
</script>

<style></style>
